.WeatherCard{
    width: 600px;
    margin:auto;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: oblique;
}

.WeatherCard_Card {
    max-height: 250px;
    margin-bottom: 1rem;
    border-radius: 0;
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
  
.WeatherCard_Card:hover{
    cursor: pointer;
}