.WeatherHome {
    /* put around the vertical center of page */
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    background: url("https://previews.123rf.com/images/9dreamstudio/9dreamstudio1910/9dreamstudio191000975/131023212-weather-forecast-concept-sunny-and-rainy-blue-background-top-view-copy-space.jpg");
    background-size: cover;
    color:white;
    font-style: oblique;
}

.WeatherHome-title{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 50px;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
}
  
.sub{
  font-size: 22px;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
}

.WeatherHome-button {
  font-style: oblique;
  color: #fff;
  padding: 15px 25px;
  background-color: #f54cfb;
  background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  font-weight: bold;
  font-size: 18px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  cursor: pointer;

  text-decoration: none;
}

.WeatherHome-button:hover{
  color:black;
}