.WeatherSearch{
    margin-top: 30px;
    height: calc(100vh - 250px);
}

.sliderimg{
    width: 1000px;
    height:390px;
    object-fit:cover;
    margin-bottom: 50px;
}

.UserWeather{
    display: flex;
    flex-wrap:wrap;
}

.degreeToggle{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: oblique;
    font-weight: bold;
}