.WeatherDetail{
    width:50%;
    margin: auto;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: oblique;
}

.WeatherDetail_Sun{
    border : 0.5px solid rgb(216, 208, 208);
     box-shadow: -1px -1px 40px rgba(216, 208, 208, 0.8);
    border-radius: 25px;
}

.WeatherDetail_Hours{
    border : 0.5px solid rgb(216, 208, 208);
    border-radius: 25px;
    box-shadow: -1px -1px 40px rgba(216, 208, 208, 0.8);
    height:120px;
    overflow: auto;
    margin: auto;
    padding: 8px;
}

.WeatherDetail_Hours_EachDiv{
    display: inline-block;
    width: 55px;
    height: 70px;
    margin: 10px;
}

.WeatherDetail_Forecast{
    border : 0.5px solid rgb(216, 208, 208);
    border-radius: 25px;
    height:100px;
    overflow: auto;
    margin-top: 10px;
    padding: 8px;
    box-shadow: -1px -1px 40px rgba(216, 208, 208, 0.8);
}

.WeatherDetail_Alerts{
    border : 0.5px solid rgb(216, 208, 208);
    border-radius: 25px;
    height:100px;
    overflow: auto;
    margin-top: 10px;
    padding: 8px;
    box-shadow: -1px -1px 40px rgba(216, 208, 208, 0.8);
}

li{
    list-style-type: none;
}

.Weather-Back-Button {
    color: #fff;
    padding: 15px 25px;
    background-color: #147be9;
    background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    font-weight: bold;
    font-size: 14px;
  
    border: 0;
  
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  
    cursor: pointer;
  
    text-decoration: none;
}
  
.Weather-Back-Button:hover{
    color:black;
}
